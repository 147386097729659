@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  src: url("assets/fonts/Inter-Regular.otf") format("otf");
  src: url("assets/fonts/Inter-Regular.ttf") format("ttf");
  src: url("assets/fonts/Inter-Regular.woff") format("woff");
  src: url("assets/fonts/Inter-Regular.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  src: url("assets/fonts/Inter-SemiBold.otf") format("otf");
  src: url("assets/fonts/Inter-SemiBold.ttf") format("ttf");
  src: url("assets/fonts/Inter-SemiBold.woff") format("woff");
  src: url("assets/fonts/Inter-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  src: url("assets/fonts/Inter-Medium.otf") format("otf");
  src: url("assets/fonts/Inter-Medium.ttf") format("ttf");
  src: url("assets/fonts/Inter-Medium.woff") format("woff");
  src: url("assets/fonts/Inter-Medium.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  src: url("assets/fonts/Inter-Bold.otf") format("otf");
  src: url("assets/fonts/Inter-Bold.ttf") format("truetype");
  src: url("assets/fonts/Inter-Bold.woff") format("woff");
  src: url("assets/fonts/Inter-Bold.woff2") format("woff2");
}

* {
  font-family: "Inter";
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: "Inter";
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  overflow: hidden;
  @apply bg-bgGray;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

code {
  font-family: "Inter";
}
/* .sort-list fieldset{
  border-color: #02C39A;
  border-width: 2;
} */
